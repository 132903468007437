.start-container {
    height: 650px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
}

.strat {
    height: 80%;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    background-color: rgb(246, 236, 223);
    margin: auto;
}

.words {
    width: 62%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;

}

.sap {
    color: rgb(0, 0, 0);

    width: 80%;
}

p.sap {
    text-align: justify;
}

.imagess {
    width: 30%;
    overflow: hidden;
    object-fit: fill;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
}

.imagess img {
    height: 100%;
    width: auto;
    overflow: hidden;
    position: relative;
}

.extra {
    background-color: rgb(255, 255, 255);
    height: 100%;
    width: 8%;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1024px) {
    .start-container {
        height: 540px;
    }

    .strat {
        height: 80%;
    }

}

/* For medium devices */
@media screen and (max-width: 820px) {
    .start-container {
        height: max-content;

    }

    .strat {
        flex-wrap: wrap;
    }

    .extra {
        display: none;
    }

    .words,
    .imagess {
        width: 100%;
    }

    .imagess img {
        width: 50%;
        height: auto;
    }

    .sap,
    .pap {
        width: 80%;

    }

    .strat p {
        font-size: 20px;
    }

    .pined p {
        font-size: 20px;
    }
}


/* For small devices */
@media screen and (max-width: 480px) {

    .sap,
    .pap,
    .words,
    .imagess {
        width: 100%;
    }

    .extra {
        display: none;
    }

    .start-container {
        height: max-content;
    }

    .strat {
        height: max-content;
    }

    p.sap {
        padding: 10px;
    }

    .pap {
        padding-top: 10px;
    }

    .imagess {
        width: 100%;
        height: 80vh;
    }

    .imagess img {
        width: 100%;
        height: auto;
    }

    .pined {
        display: none;
    }
}