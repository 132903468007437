/* WhatIDo.css */
.gallery {
  height: 650px;
  background-color: rgb(247, 243, 235);
  text-align: center;
  padding: 50px;
  overflow: hidden;
}

.gap {
  margin: auto;
}

p.gap {
  text-align: justify;
}

.cards-container {
  display: flex;
  justify-content: center;
  padding: 3%;

}


.card {
  width: 22%;
  text-align: center;
  padding: 2%;
}

.card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border: 8px solid rgb(86, 73, 73);

  /* filter: grayscale(.8); */
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {}

/* For medium devices */
@media screen and (max-width: 1024px) {
  .gallery {
    padding: 0;
    height: max-content;
  }

  .gap {
    width: 80%;
  }

  .cards-container {
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .card {
    width: 40%;
  }
}

/* For small devices */
@media screen and (max-width: 480px) {
  .gallery {
    padding: 50px 0 50px 0;
    height: max-content;
  }

  p.gap {
    padding: 10px;
    font-size: larger;
  }

  .gap {
    width: auto;
  }

  .card,
  .cards-container {
    width: 100%;

  }

  .card {
    padding: 0;
  }

  .card img {
    width: 80%;
  }

  .cards-container {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 50px 0;
  }

}