.about {
    padding-top: 50px;
    position: relative;
    height: 600PX;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    background-color: rgb(249, 248, 247);
    gap: 15%;
}

.firstdiv {
    position: relative;
    height: 100%;
    width: 30%;
    padding-left: 40px;
}

.pictureframe {
    border: 10px solid black;
    height: 300px;
    width: 300px;
    transform: rotate(25deg);
    position: absolute;
    right: 80px;
}

.firstrect {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: rgba(168, 128, 54, 0.77);
    right: 0;
    bottom: 18vh;
}

.secondrect {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: rgb(172, 166, 154);
    right: 50px;
    bottom: 23vh;
}

.picture {
    width: 330px;
    height: auto;
    z-index: 1;
    position: absolute;
    right: 80px;
}

.picture img {
    width: 100%;
    height: auto;
}

.seconddiv {
    width: 40%;
    height: 50vh;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.aboutcontent {
    width: 100%;
    border: 1px solid rgb(168, 128, 54);
    padding: 10px;
}

.aboutcontent p {
    font-size: large;
    text-align: justify;

}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1024px) {
    .about {
        align-items: center;
        height: max-content;
    }
}

/* For medium devices */
@media screen and (max-width: 1024px) {

    .about {
        flex-direction: column;
        height: auto;
        padding-top: 50px;
    }

    .picture {
        position: relative;
        width: 70% !important;
        right: auto;
    }

    .aboutcontent {
        border: none;
    }

    .seconddiv {
        width: 90%;
        height: auto;
        margin: auto;
    }

    .firstdiv {
        width: 80%;
        padding: 0;

        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }


    .firstrect {
        bottom: 0;
    }

    .secondrect {
        bottom: 50px;
    }

    .aboutcontent p {
        font-size: larger;
        text-align: justify;
    }

}


/* For small devices */
@media screen and (max-width: 480px) {
    .about {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: max-content;
        padding-top: 0;
    }

    .firstdiv,
    .seconddiv {
        width: 100%;
        height: max-content;
        padding: 0;
    }

    .picture {
        width: 80%;
        position: relative;
        right: auto;
    }

    .pictureframe {
        display: none;
    }

    .aboutcontent p {
        font-size: larger;
        text-align: justify;
    }
}