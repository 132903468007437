.ham {
    position: absolute;
    width: 100%;

}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}



.drawer-list {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    transform: translate(100vw, 0);
    /* ie workaround */
    -ms-transform: translatex(-100vw);
    box-sizing: border-box;
    pointer-events: none;
    padding-top: 125px;
    transition: width 475ms ease-out, transform 450ms ease, border-radius 0.8s 0.1s ease;
    border-bottom-left-radius: 100vw;
    background-color: #3d88ce;
    background-color: rgba(69, 67, 64, 0.8);
}


.drawer-list ul {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
    pointer-events: auto;
}

.drawer-list li {
    list-style: none;
    text-transform: uppercase;
    pointer-events: auto;
    white-space: nowrap;
    box-sizing: border-box;
    transform: translatex(100vw);
    /* ie workaround */
    -ms-transform: translatex(-100vw);
}

.drawer-list li:last-child {
    margin-bottom: 2em;
}

.drawer-list li a {
    text-decoration: none;
    color: #FEFEFE;
    text-align: center;
    display: block;
    padding: 1rem;
    font-size: calc(24px - 0.5vw);
}

@media (min-width: 768px) {
    .drawer-list li a {
        text-align: right;
        padding: 0.5rem;
    }
}

.drawer-list li a:hover {
    cursor: pointer;
    background-color: #88c2f8;
    background-color: rgba(17, 132, 240, 0.5);
}

input.hamburger {
    display: none;
}

input.hamburger:checked~.drawer-list {
    transform: translatex(0);
    border-bottom-left-radius: 0;
}

input.hamburger:checked~.drawer-list li {
    transform: translatex(0);
}

input.hamburger:checked~.drawer-list li:nth-child(1) {
    transition: transform 1s 0.08s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(2) {
    transition: transform 1s 0.16s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(3) {
    transition: transform 1s 0.24s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(4) {
    transition: transform 1s 0.32s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(5) {
    transition: transform 1s 0.4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(6) {
    transition: transform 1s 0.48s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(7) {
    transition: transform 1s 0.56s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(8) {
    transition: transform 1s 0.64s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(9) {
    transition: transform 1s 0.72s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(10) {
    transition: transform 1s 0.8s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(11) {
    transition: transform 1s 0.88s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(12) {
    transition: transform 1s 0.96s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(13) {
    transition: transform 1s 1.04s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(14) {
    transition: transform 1s 1.12s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(15) {
    transition: transform 1s 1.2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(16) {
    transition: transform 1s 1.28s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(17) {
    transition: transform 1s 1.36s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(18) {
    transition: transform 1s 1.44s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(19) {
    transition: transform 1s 1.52s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(20) {
    transition: transform 1s 1.6s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(21) {
    transition: transform 1s 1.68s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(22) {
    transition: transform 1s 1.76s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(23) {
    transition: transform 1s 1.84s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(24) {
    transition: transform 1s 1.92s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(25) {
    transition: transform 1s 2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(26) {
    transition: transform 1s 2.08s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(27) {
    transition: transform 1s 2.16s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(28) {
    transition: transform 1s 2.24s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(29) {
    transition: transform 1s 2.32s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(30) {
    transition: transform 1s 2.4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(31) {
    transition: transform 1s 2.48s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(32) {
    transition: transform 1s 2.56s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(33) {
    transition: transform 1s 2.64s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(34) {
    transition: transform 1s 2.72s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(35) {
    transition: transform 1s 2.8s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(36) {
    transition: transform 1s 2.88s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(37) {
    transition: transform 1s 2.96s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(38) {
    transition: transform 1s 3.04s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(39) {
    transition: transform 1s 3.12s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(40) {
    transition: transform 1s 3.2s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(41) {
    transition: transform 1s 3.28s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(42) {
    transition: transform 1s 3.36s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(43) {
    transition: transform 1s 3.44s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(44) {
    transition: transform 1s 3.52s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(45) {
    transition: transform 1s 3.6s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(46) {
    transition: transform 1s 3.68s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(47) {
    transition: transform 1s 3.76s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(48) {
    transition: transform 1s 3.84s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(49) {
    transition: transform 1s 3.92s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li:nth-child(50) {
    transition: transform 1s 4s cubic-bezier(0.29, 1.4, 0.44, 0.96);
}

input.hamburger:checked~.drawer-list li a {
    padding-right: 15px;
}

input.hamburger:checked~label>i {
    background-color: transparent;
    transform: rotate(90deg);
}

input.hamburger:checked~label>i:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

input.hamburger:checked~label>i:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

input.hamburger:checked~label .close {
    color: #FEFEFE;
    width: 100%;
}

input.hamburger:checked~label .open {
    color: rgba(0, 0, 0, 0);
    width: 0;
}

label.hamburger {
    z-index: 9999;
    position: absolute;
    display: block;
    height: 50px;
    width: 50px;
}

label.hamburger:hover {
    cursor: pointer;
}

label.hamburger span .close,
label.hamburger span .open {
    text-transform: uppercase;
    font-size: 0.8em;
    left: 10%;
    color: black;
    align-text: center;
    position: absolute;
    transform: translateY(50px);
    text-align: center;
    overflow: hidden;
    transition: width 0.25s 0.35s, color 0.45s 0.35s;
}

label.hamburger span .close {
    color: rgba(0, 0, 0, 0);
    right: 0;
    width: 0;
}

label.hamburger span .open {
    color: #000000;
    width: 100%;
}

label.hamburger>i {
    position: absolute;
    width: 50%;
    height: 2px;
    top: 50%;
    background-color: black;

    pointer-events: auto;
    transition-duration: 0.35s;
    transition-delay: 0.35s;
}

label.hamburger>i:before,
label.hamburger>i:after {
    position: absolute;
    display: block;
    width: 50%;
    height: 2px;
    /* left: 50%; */
    right: 10px;
    background-color: black;
    content: "";
    transition: transform 0.35s;
    transform-origin: 50% 50%;
}

label.hamburger>i:before {
    transform: translate(-50%, -14px);
}

label.hamburger>i:after {
    transform: translate(-50%, 14px);
}

label.hamburger {
    position: fixed;
    top: 15px;
    right: 15px;
}