.pinned {
    background-color: black;
    height: max-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
    display: none;
    padding: 10px;
}

p.pap {
    text-align: justify;
}

.pinned-inner {
    width: 100%;
}

.pinned-list {
    width: 100%;
    padding: 0%;
}

.pinned-list li img {
    height: 375px;
    margin: auto;
}

.goalss {
    width: 80%;
    margin: auto;
    text-align: start;
    list-style: disc;
    display: flex;
    /* gap: 25px; */
    flex-wrap: wrap;
    color: rgb(208, 210, 211);
    flex-direction: column;
}

@media screen and (max-width: 1024px) {
    .pinned {
        display: block;
    }
}