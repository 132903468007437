.hero {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 100px;
}

.leftleft {
    width: 40%;
}

.leftside {
    opacity: 0;
    width: 60%;
    background-color: black;
    height: 75vh;
    display: flex;
    align-items: center;
    overflow: visible;
    position: relative;
}

.leftside ul {
    padding-left: 56px;
    list-style: none;
    color: antiquewhite;
    height: 80%;
    display: flex;
    width: 50px;
    flex-direction: column;
    justify-content: space-evenly;
}

.leftside ul li {
    font-size: 46px;
}

.overlay {
    width: 437px;
    height: 30vh;
    position: absolute;
    top: 12%;
    left: 45%;

}

.overlay img {
    width: 100%;
    height: auto;

}

.rightside {
    width: 60%;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contents {
    display: flex;
    flex-direction: column;
    height: 65%;
    align-items: center;
    justify-content: space-between;
}

.contents h1 {
    font-size: 76px;
    margin: 0;
}

.titles {
    background-color: black;
    color: antiquewhite;
    width: 60%;
}

/* icon*/

#scroll-down {
    display: block;
    position: relative;
    padding-top: 79px;
    text-align: center;
}

#scroll-title {
    display: block;
    text-transform: uppercase;
    color: black;
    font-family: "raleway";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4em;
    text-align: center;
    transform: rotate(90deg);
    margin-bottom: 45px;
}

#scroll-down::before {
    -webkit-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
    /* Safari 4+ */

    -moz-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
    /* Fx 5+ */

    -o-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
    /* Opera 12+ */

    animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
    /* IE 10+, Fx 29+ */

    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 90px;
    background: black;
    content: ' ';
}

@-webkit-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }

    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50.1% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    100% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}

@-moz-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }

    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50.1% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    100% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}

@-o-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }

    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50.1% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    100% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}

@keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }

    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50.1% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    100% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}

/*button*/

a {
    text-decoration: none;
    color: inherit;
    margin: 0;
}

.cta {
    position: relative;
    margin: 0;
    padding: 19px 22px;
    transition: all .2s ease;
}

.cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 28px;
    background: rgb(255, 255, 255);
    width: 56px;
    height: 56px;
    transition: all .3s ease;
}

.cta span {
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
    letter-spacing: .25em;
    text-transform: uppercase;
    vertical-align: middle;
}

.cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #111;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all .3s ease;
}

.cta:hover:before {
    width: 100%;
    background: #ffffff;
}

.cta:hover svg {
    transform: translateX(0);
}

.cta:active {
    transform: scale(0.96);
}

.scroll-container {
    position: absolute;
    bottom: 20px;
    right: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-down {
    font-size: 36px;
    margin-right: 10px;
}

.scroll-text {
    font-size: 18px;
}

.scrolll {
    position: relative;
    height: 90px;
    width: 100%;
}


.shape {
    position: absolute;
    width: .2px;
    height: .2px;
    background-color: #0000004e;
    transform: rotate(45deg);
    border-bottom: solid 10px transparent;
    border-right: solid 10px transparent;
}

.shape1 {
    top: 50px;
    left: 50px;
}

.shape2 {
    top: 120px;
    right: 50px;
}

.shape3 {
    bottom: 50px;
    left: 120px;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {}

/* For medium devices */
@media screen and (max-width: 1024px) {
    .hero {
        flex-direction: column;
        height: max-content;
        padding-top: 60px;
    }

    .pined {
        display: none;
    }

   

    .leftleft,
    .leftside {
        width: 100%;
    }

    .leftside {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        height: max-content;
        background-color: inherit;
    }

    .overlay {
        position: relative;
        width: 50%;
        height: auto;
        left: 0;
    }

    .leftside ul {
        list-style: none;
        color: rgb(0, 0, 0);
        padding: 0;
        display: flex;
        height: fit-content;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .rightside {
        width: 100%;
        height: max-content;
    }

    .contents {
        height: max-content;
        width: 100%;
    }

    .contents p {
        font-size: larger;
    }

    .contents h1 {
        font-size: 56px;
        margin: 20px 0;
    }

    .titles {
        width: 70%;
        font-size: 20px;
        margin: 20px 0;
    }

    .secondary-cursor {
        display: none;
    }

    .cta {
        margin: 30px 0;
    }

    .scrolll {
        display: none;
    }


}


/* For small devices */
@media screen and (max-width: 480px) {
    .hero {
        padding-top: 60px;
        flex-direction: column;
        height: max-content;
    }

    .leftleft,
    .leftside {
        width: 100%;
    }

    .leftside {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: max-content;
        justify-content: center;
    }

    .overlay {
        position: relative;
        width: 60%;
        height: auto;
        left: 0;
        top: auto;
        padding-right: 10%;
    }

    .leftside ul {
        list-style: none;
        color: rgb(0, 0, 0);
        padding: 0;
        display: flex;
        height: 60%;
        width: 10%;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0;
        gap: 20px;

    }

    .leftleft ul li {
        font-size: 30px;
    }

    .rightside {
        width: 100%;
        height: max-content;
    }

    .contents {
        height: max-content;
        width: 100%;
    }

    .contents h1 {
        font-size: 42px;
    }

    .titles {
        width: 100%;
    }

    .secondary-cursor {
        display: none;
    }

    .scrolll {
        display: none;
    }


}