.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: antiquewhite;
    margin-bottom: 20px;
    z-index: 999;
    height: 80px;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
}

.items {
    width: 50%;
    color: white;
}

.logo {
    width: 100px;
    padding-left: 50px;
    margin: 0;
}

.logo {
    display: flex;
    justify-content: flex-start;
}

.nav_item {
    width: 100px;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {}

/* For medium devices */
@media screen and (max-width: 768px) {
    .nav {
        display: none;
    }
}


/* For small devices */
@media screen and (max-width: 350px) {
    .nav {
        display: none;
    }
}