.backg {
    margin-top: 50px;
    height: 500px;
    position: relative;
    padding: 50px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.latest {
    height: 100%;
    position: relative;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.kamanav {
    position: absolute;
    display: flex;
    width: 100%;
    z-index: 10;
    background-color: black;
    color: white;
    height: 50px;
    top: 50px;
    align-items: center;
    justify-content: center;
}

.info {

    position: relative;
    width: 50%;
    /* top: 200px;
    left: 400px; */
    font-size: medium;
}

.info p {
    text-align: justify;
}

.sect {
    position: relative;
    width: 20%;
}

.gara {
    position: relative;
    width: 25%;
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%);
}

.gara img {
    width: 100%;
    height: auto;
}

.entre {
    /* position: absolute; */
    width: 100%;
    /* top: 250px;
    left: 150px; */
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%);
    z-index: 11;
}

.entre img {
    width: 100%;
    height: auto;
}

.rect1 {
    position: absolute;
    top: 200px;
    left: 100px;
    width: 15%;
    height: 250px;
    border: 5px solid black;
}

/*butt*/


.btn-slide2 {
    position: relative;
    display: inline-block;
    height: 50px;
    width: 200px;
    line-height: 50px;
    padding: 0;
    border-radius: 50px;
    background: rgb(252, 236, 219);
    margin: 10px;
    transition: .5s;
}

.btn-slide2 {
    border: 2px solid #000000;
}



.btn-slide2:hover {
    background-color: #000000;
}

.btn-slide:hover span.circle,
.btn-slide2:hover span.circle2 {
    left: 100%;
    margin-left: -45px;
    background-color: #fdfdfd;
    color: #0099cc;
}

.btn-slide2:hover span.circle2 {
    color: #000000;
}

.btn-slide:hover span.title,
.btn-slide2:hover span.title2 {
    left: 40px;
    opacity: 0;
}

.btn-slide:hover span.title-hover,
.btn-slide2:hover span.title-hover2 {
    opacity: 1;
    left: 40px;
}

.btn-slide span.circle,
.btn-slide2 span.circle2 {
    display: block;
    background-color: #0099cc;
    color: bisque;
    position: absolute;
    float: left;
    margin: 5px;
    line-height: 42px;
    height: 40px;
    width: 40px;
    top: 0;
    left: 0;
    transition: .5s;
    border-radius: 50%;
}

.btn-slide2 span.circle2 {
    background-color: #000000;
}

.btn-slide span.title,
.btn-slide span.title-hover,
.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
    position: absolute;
    left: 90px;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    color: #30abd5;
    transition: .5s;
}

.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
    color: #000000;
    left: 80px;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
    left: 80px;
    opacity: 0;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
    color: #fff;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {}

/* For medium devices */
@media screen and (max-width: 1024px) {
    .backg {
        padding: 20px;
        height: max-content;

    }

    .latest {
        gap: 0;
    }

    .sect {
        display: none;
    }

    .info {
        width: 55%;
        padding: 8px;
    }

    .gara {
        width: 40%;
    }

    .info p {
        font-size: larger;
        text-align: justify;
    }

    .kamanav {
        width: 100%;
        right: auto;
        left: auto;
        top: 20px;
        position: relative;
    }
}


/* For small devices */
@media screen and (max-width: 480px) {
    .backg {
        padding: 0%;
        height: auto;
    }

    .kamanav,
    .info {
        position: relative;
        width: 90%;
        left: auto;
        top: auto;
    }

    .info p {
        font-size: larger;
        text-align: justify;
    }

    .latest {
        flex-direction: column-reverse;
    }

    .entre,

    .sect {
        display: none;
    }

    .gara {
        position: relative;
        width: 70%;
        right: auto;
        top: auto;
        margin: auto;
        clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%)
    }

}