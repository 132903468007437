.pined {
  overflow: hidden;
  background-color: black;
}

.pined .pined-inner {
  position: relative;
  display: flex;
  align-items: center;
  height: 70vh;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.pined .pined-list {
  position: absolute;
  left: 0;
  display: flex;
  gap: 60px;
}

.pined .pined-list li {
  width: 600px;
  aspect-ratio: 16/9;
  overflow: hidden;
}

.pap {
  color: rgb(208, 210, 211);
  padding-top: 20px;
  width: 90%;
  margin: auto;
}

.goals {
  margin: auto;
  text-align: start;
  list-style: disc;
  display: flex;
  gap: 25px;
  justify-content: center;
}

.pined .pined-list li img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}