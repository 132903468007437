.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;

}

.cocontent {
    width: 100%;
    border: 2px solid rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.pinss {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.method {
    width: 200px;
    height: 100px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: start;
    flex-wrap: wrap;
}

.one,
.two {
    width: 350px;
}

.one {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.inn {
    background-color: white;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 8px;
}

.one input {
    width: 70%;
    border: none;
    padding: 8px;
    border-radius: 10px;
}

.one input:focus-visible {
    border: none;
    outline: none;
}

.two {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.two textarea {
    resize: none;
    border-radius: 10px;
    border: none;
    height: 80%;
    width: 100%;
    margin-bottom: 10px;
}

.two textarea:focus-visible {
    outline: none;
}

.form label {
    font-weight: 600;
}

.cocontent button {
    padding: 10px;
    width: 200px;
    border: rgb(0, 0, 0) solid 2px;
    margin: auto;
    border-radius: 10px;
    background-color: rgb(0, 0, 0);
    color: antiquewhite;
    transition: 1s;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
}

.cocontent button:hover {
    background-color: rgb(255, 255, 255);
    color: black;

}

.cocontent button svg {
    font-size: 20px;
    width: 30px;
    color: antiquewhite;
    fill: antiquewhite;
}

.method svg {
    font-size: 30px;
}