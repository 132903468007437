.foot {
    position: relative;
    background-color: black;
    color: bisque;

}

.footer {

    width: 100%;
    position: relative;
    height: 200px;
    background-color: #000000;
    background-image: url('../../assets/images/wave.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.hehe {
    mix-blend-mode: difference;


    color: #f6ecdf;
}

.hehe h3 {
    font-size: 34px;
}


.company-name {
    width: 20%;
}

.follow-us,
.call-us {
    width: 30%;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.follow-us h3,
.call-us h3 {
    margin: 0;
}

.follow-us ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.follow-us li {
    display: inline-block;
    margin-right: 10px;
}

.foosocials {
    font-size: 30px;
}

@media screen and (max-width: 540px) {
    .footer {
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        gap: 20px;
        height: max-content;
        color: bisque;
    }

    .follow-us,
    .call-us,
    .company-name {
        width: 100%;
    }

}